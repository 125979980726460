import {useEffect, useRef, useState} from 'react'

const SDXLogger = `[Event Capture ${import.meta.url.split('src')[1]}]`

export const useWs = (url: string): [boolean, string, (data: string) => void] => {

  const [isReady, setIsReady] = useState(false)
  const [val, setVal] = useState<any>(null)
  const [retryCount, setRetryCount] = useState(0)

  const ws = useRef<WebSocket | null>(null)
  const retryTimeout = useRef<NodeJS.Timeout | null>(null)

  const connect = () => {
    const socket = new WebSocket(url)

    socket.onopen = () => {
      setIsReady(true)
      setRetryCount(0) // Reset retry count on successful connection
    }

    socket.onmessage = (event) => setVal(event.data)

    socket.onclose = () => {
      setIsReady(false)
      ws.current = null

      // Attempt to reconnect after a delay
      const delay = Math.min(1000 * 2 ** retryCount, 30000) // Max delay of 30 seconds
      retryTimeout.current = setTimeout(() => {
        setRetryCount((prev) => prev + 1)
        connect()
      }, delay)
    }

    socket.onerror = (error) => {
      console.error(SDXLogger, 'WebSocket error:', error)
      socket.close()
    }

    ws.current = socket
  }

  useEffect(() => {
    connect()

    return () => {
      if (ws.current) ws.current.close()
      if (retryTimeout.current) clearTimeout(retryTimeout.current)
    }
  }, [url])

  // Safe wrapper for the `send` method
  const send = (data: string) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(data)
    }
    else {
      console.error(SDXLogger, 'WebSocket is not open. Unable to send message.')
    }
  }

  return [isReady, val, send]
}