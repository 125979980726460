export const region = process.env.REACT_APP_DEPLOY_REGION
const host = 'simplicitydx.com'

// Configure Backend Service URLs
export const productServiceUrl = `https://${region}.product-service.${host}`
export const socialServiceBaseUrl = `https://${region}.social-service.${host}`
export const componentServiceBaseUrl = `https://${region}.component-service.${host}`
export const ecommerceServiceBaseUrl = `https://${region}.ecommerce-service.${host}`
export const analyticsServiceBaseUrl = `wss://${region}.analytics-service.${host}`

export const pageShopName = 's'
export const pagePlatformName = 'p'
export const hostOverrideName = 'hostname'
