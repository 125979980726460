import {createContext, FC, ReactNode, useEffect} from 'react'
import {getCartItems, setCartItems} from './utils/cartUtils'
import {useWs} from './websocket/WebSocketManager'
import {isLocalhost} from '../utils/helpers'
import {wsResponse} from './websocket/type'
import {analyticsServiceBaseUrl} from '../api/config'
import {getShopDomain} from './utils/getShopDomain'

export const initialContext = {
  init: () => {}, // we can probably self init this - we can remove it later...
  shop: '',
  setCartItems,
  getCartItems,
  browserWSSendData: (data: string) => {}, // not sure if we need to expose this function
  mlWSSendData: (data: string) => {}, // not sure if we need to expose this function
  addToCartEvent: (data: any) => {},
  checkoutInitiatedEvent: (data: any) => {},
  mlImpressionEvent: (data: any) => {},
  mlClickEvent: (data: any) => {},
  mlViewEvent: (data: any) => {},
}
export const EventCapture = createContext(initialContext)

const disableEventCapture = ((children: ReactNode) => {
  return (
    <EventCapture.Provider
      value={initialContext}
    >
      {children}
    </EventCapture.Provider>
  )
})

const SDXLogger = `[Event Capture ${import.meta.url.split('src')[1]}]`

export const EventCaptureProvider: FC<{children: ReactNode}> = ({children}) => {
  // delete when we are ready for release
  if (!isLocalhost()) return disableEventCapture(children)

  const shopDomain = getShopDomain()

  // Init websockets
  const [browserWSIsReady, browserWSMessage, browserWSSend] = useWs(`${analyticsServiceBaseUrl}/v2/event/browser?shop=${shopDomain}`)
  const [mlWSIsReady, mlWSMessage, mlWSSend] = useWs(`${analyticsServiceBaseUrl}/v1/session/`)

  const browserWSSendData = ((payload: any) => {
    if (browserWSIsReady) {
      // to do, attach metadata, etc
      browserWSSend(payload)
    }
  })
  const mlWSSendData = ((payload: any) => {
    if (mlWSIsReady) {
      // to do, attach metadata, etc
      mlWSSend(payload)
    }
  })

  // Listen for browser ws response
  useEffect(() => {
    if (browserWSMessage) {
      try {
        const data:wsResponse = JSON.parse(browserWSMessage)
        console.log(SDXLogger, 'Browser WS Received: ', data)
        if (data.status === 'INVALID') {
          console.warn(SDXLogger, 'Browser WS Received an invalid response. ', browserWSMessage)
        }
      }
      catch (error) {
        console.error(SDXLogger, 'failed to decode Browser ws message: ', browserWSMessage)
      }
    }
  }, [browserWSMessage])

  // Listen for ml ws response
  useEffect(() => {
    if (mlWSMessage) {
      try {
        const data:wsResponse = JSON.parse(mlWSMessage)
        console.log(SDXLogger, 'ML WS Received: ', data)
        if (data.status === 'INVALID') {
          console.warn(SDXLogger, 'ML WS Received an invalid response. ', mlWSMessage)
        }
      }
      catch (error) {
        console.error(SDXLogger, 'failed to decode ML ws message: ', mlWSMessage)
      }
    }
  }, [mlWSMessage])

  // ###### INIT ######
  const init = (() => {
    // @Reslav - init Event Manager
  })

  // ###### external functions ######
  const addToCartEvent = ((data: any) => {
    // to do
  })

  const checkoutInitiatedEvent = ((data: any) => {
    // to do
  })

  const mlImpressionEvent = ((data: any) => {
    // to do
  })

  const mlClickEvent = ((data: any) => {
    // to do
  })

  const mlViewEvent = ((data: any) => {
    // to do
  })

  // quick test environment
  useEffect(() => {
    if (isLocalhost()) {

      setTimeout(() => {
        browserWSSendData('{"eventId":"22f7b710-f126-42ae-b78b-06929e1f7358","shop":"sdx-qa-test.myshopify.com","eventType":"NOT_BOUNCED","metadata":{"timestamp":1733829283902,"shop":"sdx-qa-test.myshopify.com","sessionId":"219c9b97-8c7f-a7ec-b219-072adb2490d0","userId":"29c865a3-9873-34b3-adba-3ec3db7f278f","componentOrderId":"58c23f4e-29c6-573f-bc86-0e9a97c6c879","pageMLOrderType":"RANDOM","landingPageId":"a9N7qwdDetMt","currentPageId":"null","sdxCampaignId":"a9N7qwdDetMt","fbc":"","fbp":"fb.1.1729670289194.746803957734204214"}}')
        mlWSSendData('{"eventId":"6b52e84f-26c7-4fa6-8b32-18103b16ees7","shop":"sdx-qa-test.myshopify.com","eventType":"COMPONENT_IMPRESSION","payload":{"componentId":"813e3038-6f86-3995-0b08-3bf51a20e5bb","pageId":"something"}}')
      }, 3000)

    }
  }, [browserWSIsReady, mlWSIsReady])

  return (
    <EventCapture.Provider
      value={{
        init,
        shop: '',
        setCartItems,
        getCartItems,
        browserWSSendData,
        mlWSSendData,
        addToCartEvent,
        checkoutInitiatedEvent,
        mlImpressionEvent,
        mlClickEvent,
        mlViewEvent,
      }}
    >
      {children}
    </EventCapture.Provider>
  )
}